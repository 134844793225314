export default {
  ['en-US']: {
    globalLayout: {
      footer: {
        texts: {
          emailLabel: 'Email',
          phoneLabel: 'Phone',
          mediasLabel: 'Social Medias & Contact',
          rights: 'All rights reserved',
        },
      },
      buttons: {
        homeLabel: 'Home',
        aboutLabel: 'About',
        hardSkillsLabel: 'Hard Skills',
        softSkillsLabel: 'Soft Skills',
        projectsLabel: 'Projects',
        contactLabel: 'Contact',
        scrollToTopTooltip: 'Scroll to top',
      },
    },
    home: {
      buttons: {
        hireMeLabel: 'Hire Me',
        downloadResumeLabel: 'Download Resume',
      },
      texts: {
        title: {
          hello: 'Hello,',
          presentation: 'This is Ezequiel Lara,',
          role: 'Professional Software Developer',
        },
      },
    },
    about: {
      texts: {
        title: 'About me',
        background: {
          title: 'Background',
          description:
            "My name is Ezequiel Rosa Lara, and I code since 2016, when I started as a Game Developer student and then moved to software development. My first job as a Frontend Developer started in 2019. At this time, I didn't know anything about frontend and it’s technologies, but one month after I started, I was already creating new projects from scratch. I always loved technology and the way it turns easier people’s lives, and whatever I can do to improve and innovate, be sure I’ll do it.",
        },
        hobbies: {
          title: 'Hobbies',
          description:
            'Since I was a kid, I was always playing games and being a competitive child among my friends. Until now, I love to spend time playing video games, but also, I like to learn new skills, play guitar, sing and all kinds of artistic stuff.',
        },
      },
    },
    hardSkills: {
      texts: {
        title: 'Hard Skills',
      },
    },
    softSkills: {
      texts: {
        title: 'Soft Skills',
        proactive: {
          title: 'Proactive',
          description:
            'Always looking for improvements and taking the lead in new solutions',
        },
        altruistic: {
          title: 'Altruistic',
          description: 'I’m always trying to help those in need',
        },
        problemSolver: {
          title: 'Problem Solver',
          description:
            'Good insights and thinking out of the box, helps me to find the source of the problem faster',
        },
        creativity: {
          title: 'Creative',
          description:
            'I’m always looking for improving things in the most creative way',
        },
        teamWork: {
          title: 'Team Work',
          description:
            'Whenever I need, I don’t hesitate to ask help for those who can help me. I also like to share knowledge',
        },
        resilience: {
          title: 'Resilience',
          description:
            'I won’t stop trying everything I can until I get it done. Nothing is impossible, I just don’t figured it out yet',
        },
        flexibility: {
          title: 'Flexibility',
          description:
            'I’m always open to new point of views, new ideas and insights',
        },
        ethic: {
          title: 'Principles',
          description:
            'Knowing what is right in every situation helps to have a north on the way you want to do things',
        },
      },
    },
    projects: {
      texts: {
        title: 'Projects',
      },
      cards: [
        {
          title: 'AL5 (Mobile)',
          company: { title: 'Company:', company: 'Palmsoft' },
          description: {
            title: 'Description',
            description:
              'AL5 is a digital bank focused on helping the agribusiness, providing an additional credit for farmers, not only for production cost, but also for machinery financing and inputs reselling.',
          },
          whatIHaveDone: {
            title: 'What I have done',
            description: [
              'I helped on building a intro screen with a presenting video',
              'Made a bank statement screen',
              'I integrated APIs',
            ],
          },
          technologies: {
            title: 'Technologies',
            description: [
              'React Native',
              'React Native Paper',
              'Axios',
              'Android Studio',
            ],
          },
        },
        {
          title: 'AL5 (Web)',
          company: { title: 'Company:', company: 'Palmsoft' },
          description: {
            title: 'Description',
            description:
              'AL5 is a digital bank focused on helping the agribusiness, providing an additional credit for farmers, not only for production cost, but also for machinery financing and inputs reselling.',
          },
          whatIHaveDone: {
            title: 'What I have done ?',
            description: [
              'I helped on building a payment screen, integrated with payment API',
            ],
          },
          technologies: {
            title: 'Technologies',
            description: ['ReactJS', 'Material-UI', 'Axios'],
          },
        },
        {
          title: 'Happy Help',
          company: { title: 'Company:', company: 'Palmsoft' },
          description: {
            title: 'Description',
            description:
              'Happy Help is a PWA (Progressive Web Application) project, developed for all platforms. This project consists on being a social media app, where users can trade their services for a fictional coin, that can be used to buy other services from another user. After complete some challenges inside the platform, users can earn rewards, including discounts for companies products, promotions and this kind of stuff. These companies pay for a specific plan that gives them credits to show banners and promote themselves for users inside the app.',
          },
          whatIHaveDone: {
            title: 'What I have done ?',
            description: [
              'I built the project from scratch, using ReactJS',
              'I Styled components with Material-UI features',
              'I Configured Firebase Notifications',
              'I Configured Capacitor to use native API from mobiles (Android and iOS)',
              'I integrated APIs',
            ],
          },
          technologies: {
            title: 'Technologies',
            description: [
              'ReactJS',
              'Capacitor',
              'Firebase Notifications',
              'Material-UI',
              'JavaScript',
            ],
          },
        },
        {
          title: 'EmbarcaBI',
          company: { title: 'Company:', company: 'Palmsoft' },
          description: {
            title: 'Description',
            description:
              'EmbarcaBI is an internal system for bus ticket management and real-time tracking. It was created for internal team to monitor all the data coming from bus travels, like ticket prices, sales, tracking and reports.',
          },
          whatIHaveDone: {
            title: 'What I have done ?',
            description: [
              'I built the project from scratch, using ReactJS',
              'I created all the components with no styling library, only pure JavaScript, HTML and CSS',
              'Integrated data from 3 Databases through APIs',
            ],
          },
          technologies: {
            title: 'Technologies',
            description: ['ReactJS', 'HTML', 'CSS'],
          },
        },
        {
          title: 'Backoffice Intelbras',
          company: { title: 'Company:', company: 'Intelbras' },
          description: {
            title: 'Description',
            description:
              'Backoffice is an internal system that manages all the ecommerce ecosystem. All orders, products data, clients data passes through this system. Also, it is used to pay resellers for their part of the sales.',
          },
          whatIHaveDone: {
            title: 'What I have done ?',
            description: [
              'I refactored legacy code, replacing for modern design patterns',
              'I created new features aligned to the company needs',
              'I integrated new features with new APIs',
            ],
          },
          technologies: {
            title: 'Technologies',
            description: ['ReactJS', 'NextJS', 'TailwindCSS', 'Material-UI'],
          },
        },
      ],
    },
    contact: {
      texts: {
        title: 'Contact',
        subtitle:
          'Fill the form to contact me. Describe your projects, proposals and needs. Soon I will reply to you, so we can talk about it.',
      },
      form: {
        nameInputLabel: 'Name',
        iAmLabel: 'I am',
        peopleRadioButtonLabel: 'Person',
        companyRadioButtonLabel: 'Company',
        emailInputLabel: 'Email',
        messageInputLabel: 'Message',
        emailErrorMessage: 'Invalid email',
        formSuccessMessage: 'Message sent successfully',
        formErrorMessage: 'An error occured trying to send the message',
      },
      buttons: {
        sendLabel: 'Send',
      },
    },
  },
  ['pt-BR']: {
    globalLayout: {
      footer: {
        texts: {
          emailLabel: 'E-mail',
          phoneLabel: 'Telefone',
          mediasLabel: 'Redes Sociais & Contato',
          rights: 'Todos os direitos reservados',
        },
      },
      buttons: {
        homeLabel: 'Início',
        aboutLabel: 'Sobre',
        hardSkillsLabel: 'Habilidades Técnicas',
        softSkillsLabel: 'Habilidades Sociais',
        projectsLabel: 'Projetos',
        contactLabel: 'Contato',
        scrollToTopTooltip: 'Voltar ao topo',
      },
    },
    home: {
      buttons: {
        hireMeLabel: 'Me contrate',
        downloadResumeLabel: 'Baixar Currículo',
      },
      texts: {
        title: {
          hello: 'Olá,',
          presentation: 'Sou Ezequiel Lara,',
          role: 'Desenvolvedor de Software Profissional',
        },
      },
    },
    about: {
      texts: {
        title: 'Sobre mim',

        background: {
          title: 'Experiência',
          description:
            'Meu nome é Ezequiel Lara, e eu programo desde 2016, quando comecei como um estudante de Desenvolvimento de Jogos e então mudei para o Desenvolvimento de Software. Meu primeiro trabalho como Desenvolvedor Frontend começou em 2019. Nesse momento, eu não sabia nada sobre frontend e suas tecnologias, mas um mês depois de começar, eu já estava criando novos projetos do zero. Eu sempre fui apaixonado por tecnologia e a maneira que isso torna a vida das pessoas mais fácil, e o que eu conseguir fazer para melhorar e inovar, tenha certeza de que irei fazer.',
        },
      },
    },
    hardSkills: {
      texts: {
        title: 'Habilidades Técnicas',
      },
    },
    softSkills: {
      texts: {
        title: 'Habilidades Sociais',
        proactive: {
          title: 'Proatividade',
          description:
            'Sempre procurando por melhorias e tomando à frente em novas soluções',
        },
        altruistic: {
          title: 'Altruísta',
          description: 'Estou sempre procurando ajudar aqueles que precisam',
        },
        problemSolver: {
          title: 'Solucionador de Problemas',
          description:
            'Boas intuições e pensar fora da caixa, me ajudam a achar a fonte do problema de forma mais rápida',
        },
        creativity: {
          title: 'Criatividade',
          description:
            'Estou sempre procurando melhorar as coisas de forma mais criativa',
        },
        teamWork: {
          title: 'Trabalho em Equipe',
          description:
            'Sempre que eu preciso, não hesito em pedir ajuda para aqueles que podem me ajudar. Além disso, eu adoro compartilhar conhecimento',
        },
        resilience: {
          title: 'Resiliência',
          description:
            'Eu não irei parar de tentar até eu conseguir concluir minhas tarefas. Nada é impossível, eu apenas não descobri ainda como fazer',
        },
        flexibility: {
          title: 'Flexibilidade',
          description:
            'Estou sempre aberto para novos pontos de vista e novas ideias',
        },
        ethic: {
          title: 'Ética',
          description:
            'Saber o que é certo em todas as situações, ajuda a ter um norte na maneira que você quer fazer as coisas',
        },
      },
    },
    projects: {
      texts: {
        title: 'Projetos',
      },
      cards: [
        {
          title: 'AL5 (Mobile)',
          company: { title: 'Empresa:', company: 'Palmsoft' },
          description: {
            title: 'Descrição',
            description:
              'AL5 é um banco digital focado em ajudar o agronegócio, proporcionando um crédito adicional para agricultores, não apenas para o custo de produção, mas também para o financiamento de máquinas agricolas e revenda de insumos',
          },
          whatIHaveDone: {
            title: 'O que eu fiz',
            description: [
              'Eu ajudei a construir uma tela de introdução ao app, com um vídeo de apresentação',
              'Criei uma tela de extrato bancário, integrando com API de histórico de pagamentos',
            ],
          },
          technologies: {
            title: 'Tecnologias',
            description: [
              'React Native',
              'React Native Paper',
              'Axios',
              'Android Studio',
            ],
          },
        },
        {
          title: 'AL5 (Web)',
          company: { title: 'Empresa:', company: 'Palmsoft' },
          description: {
            title: 'Descrição',
            description:
              'AL5 é um banco digital focado em ajudar o agronegócio, proporcionando um crédito adicional para agricultores, não apenas para o custo de produção, mas também para o financiamento de máquinas agricolas e revenda de insumos',
          },
          whatIHaveDone: {
            title: 'O que eu fiz ?',
            description: [
              'Eu ajudei a construir uma tela de pagamento, integrada com uma API de pagamento',
            ],
          },
          technologies: {
            title: 'Tecnologias',
            description: [
              'React Native',
              'React Native Paper',
              'Axios',
              'Android Studio',
            ],
          },
        },
        {
          title: 'Happy Help',
          company: { title: 'Empresa:', company: 'Palmsoft' },
          description: {
            title: 'Descrição',
            description:
              'Happy Help é um projeto PWA (Progressive Web Application), desenvolvido para todas as plataformas. Esse projeto consiste em ser uma rede social, onde usuário podem trocar serviços por uma moeda fictícia, que pode ser usar para comprar serviços de outras pessoas. Depois de completar alguns desafios na plataforma, usuários podem ganhar recompensas, incluindo descontos para produtos de empresas e promoções. Essas empresas pagam por um plano específico que concede a elas créditos para exibir banners e se promoverem dentro do aplicativo.',
          },
          whatIHaveDone: {
            title: 'O que eu fiz ?',
            description: [
              'Construí o projeto do zero, usando ReactJS',
              'Estilizei componentes usando Material-UI',
              'Configurei o Firebase Notifications',
              'Configurei o Capacitor para usar APIs nativas de aparelhos móveis (Android e iOS)',
            ],
          },
          technologies: {
            title: 'Tecnologias',
            description: [
              'ReactJS',
              'Capacitor',
              'Firebase Notifications',
              'Material-UI',
              'JavaScript',
            ],
          },
        },
        {
          title: 'EmbarcaBI',
          company: { title: 'Empresa:', company: 'Palmsoft' },
          description: {
            title: 'Descrição',
            description:
              'EmbarcaBI é um sistema interno de gerenciamento de bilhete de ônibus e rastreamento em tempo real. Foi criado para o time interno do cliente monitorar todos os dados vindo das viagens de ônibus, como valores, vendas, rastreamento e relatórios.',
          },
          whatIHaveDone: {
            title: 'O que eu fiz ?',
            description: [
              'Criei o projeto do zero, usando RaectJS',
              'Criei todos os componentes sem usar biblioteca de estilização, apenas usando puro JavaScript, HTML e CSS',
              'Integrei dados de 3 banco de dados diferentes através de APIs',
            ],
          },
          technologies: {
            title: 'Tecnologias',
            description: ['ReactJS', 'HTML', 'CSS'],
          },
        },
        {
          title: 'Backoffice Intelbras',
          company: { title: 'Empresa:', company: 'Intelbras' },
          description: {
            title: 'Descrição',
            description:
              'Backoffice é um sistema interno que gerencia todo o ecosistema de e-commerce. Todos os pedidos, dados de produtos e dados de clientes passa pelo sistema. Além disso, é usado para fazer o repasse de valores para as revendas.',
          },
          whatIHaveDone: {
            title: 'O que eu fiz ?',
            description: [
              'Refatorei código legado, substituindo por padrões de código modernos',
              'Eu criei novas funcionalidades alinhadas com as necessidades da empresa',
              'Integrei novas funcionalidades com novas APIs',
            ],
          },
          technologies: {
            title: 'Tecnologias',
            description: ['ReactJS', 'NextJS', 'TailwindCSS', 'Material-UI'],
          },
        },
      ],
    },
    contact: {
      texts: {
        title: 'Contato',
        subtitle:
          'Preencha o formulário para entrar em contato comigo. Descreva seus projetos, propostas e necessidades. Em breve entrarei em contato de volta para conversarmos.',
      },
      form: {
        nameInputLabel: 'Nome',
        iAmLabel: 'Eu sou uma',
        peopleRadioButtonLabel: 'Pessoa',
        companyRadioButtonLabel: 'Empresa',
        emailInputLabel: 'E-mail',
        messageInputLabel: 'Mensagem',
        emailErrorMessage: 'E-mail inválido',
        formSuccessMessage: 'Mensagem enviada com sucesso',
        formErrorMessage: 'Ocorreu um erro ao tentar enviar a mensagem',
      },
      buttons: {
        sendLabel: 'Enviar',
      },
    },
  },
};
