'use client';

import { useContext } from 'react';
import { LanguageContext } from '../context/language';

export default function useLanguage() {
  const { language, changeLanguage } = useContext(LanguageContext);

  return {
    language: language ?? 'en-US',
    changeLanguage,
  };
}
