'use client';

import { getCookie, setCookie } from 'cookies-next';
import { ReactNode, useEffect, useState } from 'react';
import { createContext } from 'react';

export type Languages = 'pt-BR' | 'en-US' | undefined;

type LanguageContextProvider = {
  language: Languages;
  changeLanguage: () => void;
};

export const LanguageContext = createContext({} as LanguageContextProvider);

export default function LanguageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const cookieLanguage = getCookie('language') as Languages;
  const [language, setLanguage] = useState<Languages>(
    cookieLanguage ?? 'en-US'
  );
  console.log({ language });
  const [mounted, setMounted] = useState(false);

  const changeLanguage = () => {
    const newLanguage = language === 'en-US' ? 'pt-BR' : 'en-US';
    setCookie('language', newLanguage);
    setLanguage(newLanguage);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
